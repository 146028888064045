import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {ExternalLoginDto} from "../../data/external-login.dto";

@Component({
    selector: "bb-email-settings-card",
    templateUrl: "email-settings-card.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            mat-divider.mat-divider.mat-divider-inset {
                margin-left: 0;
            }
        `,
    ],
})
export class EmailSettingsCardComponent implements OnChanges {
    @Input() public settings: {
        email: string;
        externalLogins: ExternalLoginDto[];
    } = {
        email: "",
        externalLogins: [],
    };
    @Output() public changeEmail: EventEmitter<string> = new EventEmitter<string>();
    @Output() public clickDeleteLogin: EventEmitter<ExternalLoginDto> = new EventEmitter<ExternalLoginDto>();

    public readonly emailControl = new FormControl("", Validators.required);

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings.currentValue.email !== changes.settings.previousValue?.email) {
            this.emailControl.setValue(changes.settings.currentValue.email, {emitEvent: false});
        }
    }

    public saveUserEmail(email: string): void {
        if (this.emailControl.invalid) {
            return;
        }
        this.changeEmail.emit(email);
    }

    public addOrRemoveExternalLogin(externalLogin: ExternalLoginDto): void {
        if (externalLogin.Id === null && externalLogin.Provider !== null) {
            this.addSocialLogin(externalLogin.Provider);
        } else if (externalLogin.Id !== null) {
            this.clickDeleteLogin.emit(externalLogin);
        }
    }

    private addSocialLogin(provider: string): void {
        window.location.href = `/api/angular/account/external-logins/add/${provider}`;
    }
}
