import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TextValuePair } from "@bb-core/entity";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AccountResetSettingsDto } from "./account-reset-settings.dto";
import { AvvSettingsDto } from "./avv-settings.dto";
import { ExternalLoginDto } from "./external-login.dto";
import { MailNotificationSettingsDto } from "./mail-notification-settings.dto";
import { ServicePinSettingsDto } from "./service-pin-settings.dto";
import { UserProfileDto } from "./user-profile.dto";

@Injectable({ providedIn: "root" })
export class AccountRepository {
    private readonly httpClient = inject(HttpClient);
    private readonly baseUrl = `${environment.baseUrl}/account`;

    getMailNotificationSettings(): Observable<MailNotificationSettingsDto> {
        return this.httpClient.get<MailNotificationSettingsDto>(`${this.baseUrl}/mail-notification-settings`);
    }

    setEmailNotificationSettings(settings: MailNotificationSettingsDto): Observable<UserProfileDto> {
        return this.patchProfile(settings);
    }

    getDetails(): Observable<UserProfileDto> {
        return this.httpClient.get<UserProfileDto>(`${this.baseUrl}/details`);
    }

    patchProfile(user: Partial<UserProfileDto>): Observable<UserProfileDto> {
        return this.httpClient.patch<UserProfileDto>(`${this.baseUrl}/profile`, user);
    }

    getExternalLogins(): Observable<ExternalLoginDto[]> {
        return this.httpClient.get<ExternalLoginDto[]>(`${this.baseUrl}/external-logins`);
    }

    deleteExternalLogin(externalLogin: ExternalLoginDto): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/externalLogins/${externalLogin.Id}`);
    }

    getServicePinSettings(): Observable<ServicePinSettingsDto> {
        return this.httpClient.get<ServicePinSettingsDto>(`${this.baseUrl}/service-pin-settings`);
    }

    getAvvSettings(): Observable<AvvSettingsDto> {
        return this.httpClient.get<AvvSettingsDto>(`${this.baseUrl}/avv-settings`);
    }

    resetAccount(settings: AccountResetSettingsDto): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/reset`, settings);
    }

    deleteAccount(confirmDeletion: boolean): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/delete`, { ConfirmDeletion: confirmDeletion });
    }

    getCountries(): Observable<TextValuePair<string>[]> {
        return this.httpClient.get<TextValuePair<string>[]>(`${this.baseUrl}/countries`);
    }
}
