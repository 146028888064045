import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import moment from "moment";
import {SyncMode} from "../../model/sync-mode.enum";

@UntilDestroy()
@Component({
    selector: "bb-enter-reset-account-settings-dialog",
    templateUrl: "./enter-account-sync-settings-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterAccountSyncSettingsDialogComponent implements OnInit {
    public readonly minDate = moment().add(-1, "year").toDate();
    public readonly SyncModes = SyncMode;

    public readonly options: KeyValuePair<string, SyncMode>[] = [
        {Key: "label.today", Value: SyncMode.today},
        {Key: "label.last_seven_days", Value: SyncMode.last7Days},
        {Key: "label.last_thirty_days", Value: SyncMode.last30Days},
        {Key: "label.custom", Value: SyncMode.custom},
        {Key: "label.never_sync_channel", Value: SyncMode.never},
    ];

    public readonly formGroup = new FormGroup({
        SyncMode: new FormControl(SyncMode.today, {validators: [Validators.required], nonNullable: true}),
        StartDate: new FormControl(moment(new Date()).hours(0).minutes(0).seconds(0).toDate()),
    });

    public ngOnInit(): void {
        this.formGroup.controls.SyncMode.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((selectedSyncMode) => this.changeSyncModeHandler(selectedSyncMode));
    }

    private changeSyncModeHandler(selectedSyncMode: SyncMode): void {
        const today = moment(Date.now()).hours(0).minutes(0).seconds(0);
        const startDateControl = this.formGroup.controls.StartDate;

        if (selectedSyncMode === SyncMode.custom) {
            startDateControl.setValidators(Validators.required);
        } else {
            startDateControl.clearValidators();
        }

        switch (selectedSyncMode) {
            case SyncMode.today:
                startDateControl.setValue(today.toDate());
                break;
            case SyncMode.last7Days:
                startDateControl.setValue(today.add(-7, "d").toDate());
                break;
            case SyncMode.last30Days:
                startDateControl.setValue(today.add(-30, "d").toDate());
                break;
        }
        if (selectedSyncMode !== SyncMode.never) {
            startDateControl.setValue(startDateControl.value ?? new Date());
        }
    }
}
