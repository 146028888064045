<h3 mat-dialog-title>{{ "title.import_orders_for_date_range" | translate }}</h3>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div>
            <bb-select
                formControlName="SyncMode"
                [label]="'label.channel_sync_start_date' | translate"
                [options]="options"
                required
            ></bb-select>
        </div>

        <div *ngIf="formGroup.value.SyncMode === SyncModes.custom">
            <bb-datepicker
                formControlName="StartDate"
                [label]="'label.sync_start_date' | translate"
                [min]="minDate"
            ></bb-datepicker>
        </div>
    </form>
    <p *ngIf="formGroup.value.SyncMode !== SyncModes.never">
        {{ "text.orders_will_be_imported_from" | translateLabel: {"startDate:date": formGroup.value.StartDate} }}
    </p>
    <p *ngIf="formGroup.value.SyncMode === SyncModes.never">{{ "text.orders_are_not_imported" | translate }}</p>
    <p
        *ngIf="formGroup.value.StartDate | isLongPeriod: formGroup.value.SyncMode"
        class="mat-error"
        [innerHtml]="'text.html_sync_takes_long_time_for_long_periods' | translate"
    ></p>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
    <button mat-button type="button" [mat-dialog-close]="null">{{ "button.cancel" | translate }}</button>
    <button mat-button color="primary" type="button" [mat-dialog-close]="formGroup.value">
        {{ "button.save" | translate }}
    </button>
</mat-dialog-actions>
