import {Directive, ElementRef, Input, AfterViewInit} from "@angular/core";

@Directive({
    selector: "[bbIconBadge]",
})
export class BbIconBadgeDirective implements AfterViewInit {

    @Input() bbIconBadge: string;

    constructor(private el: ElementRef) {
    }

    public ngAfterViewInit(): void {
        const badge = this.el.nativeElement.querySelector(".mat-badge-content");
        badge.style.alignItems = "center";
        badge.style.justifyContent = "center";
        badge.innerHTML = `<i class="fal fa-fw ${this.bbIconBadge}"></i>`;
    }
}
