import {Component, Inject} from "@angular/core";
import {OtpService} from "@app/app/common/service/otp.service";
import {FormModel} from "@mintware-de/form-builder";
import {PasswordField} from "@app/app/common/form/types";
import {AccountSecurity, ExternalLogin} from "@app/app/common/model";
import {MessagingService, Message, InteractionService, TranslationService, ErrorHandlerService} from "@bb-core/service";


@Component({
    selector: "bb-security",
    templateUrl: "security.component.html",
})

export class SecurityComponent {

    public formModel: FormModel = {
        OldPassword: new PasswordField({label: "label.current_password"}),
        NewPassword: new PasswordField({label: "label.new_password"}),
        NewPasswordConfirmation: new PasswordField({label: "label.new_password_repeated"}),
    };
    public readonly formData: AccountSecurity = new AccountSecurity();

    public twoFactorAuthLogin: ExternalLogin = null;

    constructor(private readonly messagingService: MessagingService,
                private readonly interactionService: InteractionService,
                private readonly translator: TranslationService,
                private otpService: OtpService,
                @Inject("accountResource") private accountResource: IAccountResource,
                @Inject("authService") private authService: AuthService,
                private readonly errorHandlerService: ErrorHandlerService,
    ) {
        this.accountResource.getExternalLogins((externalLogins: ExternalLogin[]) => {
            this.twoFactorAuthLogin = externalLogins.find(l => l.Provider === "totp");
        });
    }

    public async addOrRemoveTwoFactorAuthentication(): Promise<void> {

        if (this.twoFactorAuthLogin == null) {
            if (await this.otpService.showInitializeDialog()) {
                this.accountResource.getExternalLogins((externalLogins: ExternalLogin[]) => {
                    this.twoFactorAuthLogin = externalLogins.find(l => l.Provider === "totp");
                });
            }
        } else {
            if (this.authService.hasAdminRole()) {
                const title = this.translator.translate("title.2fa_deactivation_not_possible");
                const message = this.translator.translate("text.2fa_deactivation_not_possible");
                await this.messagingService.showError(Message.transient({title, message}));
                return;
            }

            if (await this.confirmDeactivation2FA()) {

                if (await this.otpService.requestAndVerifyOtp()) {
                    this.accountResource.removeExternalLogin({id: this.twoFactorAuthLogin.Id});
                    this.twoFactorAuthLogin = null;
                    const title =  this.translator.translate("title.2fa_deactivation_successful");
                    await this.messagingService.showMessage(Message.transient({title}));
                }

            }
        }
    }
    
    private async confirmDeactivation2FA(): Promise<boolean> {
        const title =  this.translator.translate("title.should_2fa_be_deactivated");
        const message = this.translator.translate("text.should_2fa_be_deactivated");
        return this.interactionService.confirm(title, message);
    }

    public async updatePassword(formData: AccountSecurity): Promise<void> {
        if (formData.NewPassword !== formData.NewPasswordConfirmation) {
            const title = this.translator.translate("title.password_could_not_be_changed");
            const message = this.translator.translate("text.passwords_do_not_match");
            await this.messagingService.showError(Message.transient({title, message}));
            return;
        }
        await this.accountResource.changePassword(formData,
            async () => { // success
                const title = this.translator.translate("title.password_changed_successfully");
                const message = this.translator.translate("text.password_changed_successfully");
                await this.messagingService.showMessage(Message.transient({title, message}));
            },
            async e => { // error (why can't I use a try-catch here ???)
                await this.errorHandlerService.handleException(e, "text.failure_upon_password_update", false, {
                    "text.error_invalid_password": "text.error_invalid_password",
                }, "title.failure_upon_password_update", false, false);
            },
        );
    }
}
