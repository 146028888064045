import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {changeAccountEmail, deleteAccount} from "@bb/states/actions";
import {Store} from "@ngrx/store";
import {ExternalLoginDto} from "../../data/external-login.dto";
import {MailNotificationSettingsDto} from "../../data/mail-notification-settings.dto";
import {AccountSettingsStore} from "./settings.store";

@Component({
    selector: "bb-account-settings",
    templateUrl: "./settings.component.html",
    providers: [AccountSettingsStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsContainerComponent implements OnInit {
    public readonly state$ = this.settingsStore.state$;

    constructor(private readonly settingsStore: AccountSettingsStore, private readonly store: Store) {}

    public ngOnInit(): void {
        this.settingsStore.loadSettings();
    }

    public saveMailNotificationSettings(settings: MailNotificationSettingsDto): void {
        this.settingsStore.updateMailNotificationSettings(settings);
    }

    public changeEmail(email: string): void {
        this.store.dispatch(changeAccountEmail({email}));
    }

    public deleteExternalLogin(login: ExternalLoginDto): void {
        this.settingsStore.deleteExternalLogin(login);
    }

    public deleteAccount(): void {
        this.store.dispatch(deleteAccount({confirmation: true}));
    }
}
