import { Injectable } from "@angular/core";
import * as model from "@app/app/common/model";
import * as ngx from "@ngx-resource/core";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/settings/bookedservice",
})
export class BookedService extends ngx.Resource {

    @ngx.ResourceAction({
        path: "/",
    })
    public getList: ngx.IResourceMethod<void, model.BookedService[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/",
    })
    public save: ngx.IResourceMethod<model.BookedService, model.BookedService>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Delete,
        path: "/{id}",
    })
    public delete: ngx.IResourceMethod<{id: number}, model.BookedService>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
