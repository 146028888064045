import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {ClipboardService} from "@bb-core/service";
import {ServicePinSettingsDto} from "../../data/service-pin-settings.dto";

@Component({
    selector: "bb-service-pin-settings-card",
    templateUrl: "service-pin-settings-card.component.html",
    styles: [
        `
            mat-icon.mat-icon.mat-icon-inline {
                height: auto;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePinSettingsCardComponent {
    @Input() public settings: ServicePinSettingsDto = {
        ServicePin: "",
        ServicePinExpiryDate: "",
    };
    
    constructor(private readonly clipboardService: ClipboardService) {}

    public async copyToClipboard(servicePin: string): Promise<void> {
        await this.clipboardService.copy(servicePin);
    }
}
