<bb-card>
    <ng-container title>{{ "title.email_settings" | translate }}</ng-container>
    <form [formGroup]="mailNotificationSettingsForm">
        <bb-slide-toggle
            formControlName="ObjectSurveyParticipation"
            [label]="'label.object_survey_participation' | translate"
            [matTooltip]="'tooltip.object_survey_participation' | translate"
        ></bb-slide-toggle>
        <bb-slide-toggle
            formControlName="InformUserWhenOrderIsAssigned"
            [label]="'label.inform_user_when_order_is_assigned' | translate"
            [matTooltip]="'tooltip.inform_user_when_order_is_assigned' | translate"
        ></bb-slide-toggle>
    </form>
    <ng-container actions>
        <form action="https://manage.userlist.com/" method="POST" target="_blank" #userListForm>
            <input type="hidden" name="token" id="userlistToken" />
            <button mat-flat-button color="primary" (click)="userListForm.submit()">
                {{ "label.change_newsletter_settings" | translate }}
            </button>
        </form>
    </ng-container>
</bb-card>
