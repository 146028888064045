<bb-card>
    <ng-container title>{{ "title.billbee_support" | translate }}</ng-container>
    {{ "label.service_pin" | translate }}:
    <strong [matTooltip]="'tooltip.service_pin_explanation' | translate">
        {{ settings.ServicePin }}
    </strong>
    <br />
    {{ "label.valid_until" | translate }}: <strong>{{ settings.ServicePinExpiryDate | date }}</strong>
    <ng-container actions>
		<button mat-button color="primary" type="button" (click)="copyToClipboard(settings.ServicePin)">
            {{ "button.copy_pin" | translate }}
        </button>
    </ng-container>
</bb-card>
