import {Injectable} from "@angular/core";
import * as ngx from "@ngx-resource/core";
import {AvailableLanguages, LanguageDefinition} from "@shared/models";

@Injectable({providedIn: "root"})
@ngx.ResourceParams({
    pathPrefix: "/api/angular/language",
})
export class LanguageService extends ngx.Resource {

    @ngx.ResourceAction({
        path: "/",
    })
    public query: ngx.IResourceMethod<void, AvailableLanguages>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/",
    })
    public addNewLanguage: ngx.IResourceMethod<{ language: string }, void>;

    @ngx.ResourceAction({
        path: "/getall",
    })
    public queryAll: ngx.IResourceMethod<void, LanguageDefinition[]>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/delete",
    })
    public deleteMany: ngx.IResourceMethod<string[], void>;

    @ngx.ResourceAction({
        method: ngx.ResourceRequestMethod.Post,
        path: "/changedefault",
    })
    public setDefault: ngx.IResourceMethod<any, void>;

    constructor(restHandler: ngx.ResourceHandler) {
        super(restHandler);
    }
}
