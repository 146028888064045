<ng-container *ngIf="externalLogin != null">
    <button *ngIf="externalLogin.Id; else buttonNotConnected"
            mat-icon-button
            [matTooltip]="'tooltip.login_using_provider' | translate: {
                provider: externalLogin.Provider | titlecase,
                username: externalLogin.ExternalUserName || ('label.unknown' | translate)
            }"
            bbIconBadge="fa-check"
            matBadge="x"
            [matBadgeHidden]="false"
            (click)="onClick.emit()"
    >
        <ng-container *ngTemplateOutlet="icon"></ng-container>
    </button>
    <ng-template #buttonNotConnected>
        <button mat-icon-button
                [matTooltip]="'tooltip.connect_to_provider' | translate: {
                    provider: externalLogin.Provider | titlecase
                }" (click)="onClick.emit()"
        >
            <ng-container *ngTemplateOutlet="icon"></ng-container>
        </button>
    </ng-template>
</ng-container>

<ng-template #icon>
    <mat-icon fontSet="fab"
              color="default"
              [fontIcon]="'fa-' + (externalLogin.Provider == ExternalLoginProvider.facebook ? 'facebook-f' : externalLogin.Provider)"></mat-icon>
</ng-template>