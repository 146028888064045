import { Component, Input } from "@angular/core";

@Component({
    selector: "bb-named-content",
    templateUrl: "./named-content.component.html",
})
export class NamedContentComponent {
    @Input() public title: string;
    @Input() public divider: boolean;
}
