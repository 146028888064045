<bb-named-content title="{{'Account' | translate}}" divider="true">
    <div fxLayout="row">
        <div fxFlex="33%">
            <mat-card>
                <mat-card-title align="center">
                    <span class="md-headline">{{ 'Status' | translate }}
                        : {{ (isInTrial ? 'Testphase' : 'Aktiv') | translate}}</span>
                </mat-card-title>
                <mat-card-content>
                    <table class="material">
                        <thead></thead>
                        <tbody>
                        <tr *ngIf="profile != null">
                            <td>{{ 'Angemeldet seit' | translate }}</td>
                            <td>{{ profile.Registered | date:'dd.MM.yyyy' }}</td>
                        </tr>
                        <tr *ngIf="profile != null">
                            <td>{{ 'Testphase bis' | translate }}</td>
                            <td>{{ profile.TrialUntil | date:'dd.MM.yyyy' }}</td>
                        </tr>
                        <tr *ngIf="currentInvoice != null">
                            <td>{{ 'Ungezahlte / offene Gebühren' | translate }}</td>
                            <td>{{ currentInvoice.TotalBrutto | currency:'€' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-button class="pull-right" routerLink="/account/invoices-and-payment">
                        {{ 'Rechnungen / Zahlungen' | translate }}
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div fxFlex="2%"></div>
        <div fxFlex="33%">
            <mat-card>
                <mat-card-title align="center">
                    <span class="md-headline">{{ 'Billbee Cloudspeicher' | translate }}</span>
                </mat-card-title>
                <p class="clearfix"></p>
                <mat-card-content>
                    <p>{{ 'Mit diesem Modul kannst du Cloud Speicherplatz für Bilder und für digitale Dateien direkt bei Billbee nutzen.' | translate }}</p>
                    <p>
                        {{ 'Die Nutzung ist in den normalen Billbee Gebühren enthalten' |translate }}
                    </p>
                    <p class="clearfix"></p>
                    <p *ngIf="profile != null">
                        <b>Aktuelle
                            Nutzung: {{ (profile.CloudStorageUsedBytes / (1024 * 1024 * 1024)) | number:'1.3':'de'}}
                            GB</b>
                    </p>
                </mat-card-content>
                <mat-card-actions layout="row">
                    <button *ngIf="billbeeCloudService == null"
                            mat-raised-button
                            color="primary"
                            fxFlex="100"
                            type="button"
                            (click)="confirmBookBillbeeCloud($event)"
                            [disabled]="isBookingBusy">
                        {{'Billbee Cloudspeicher aktivieren'|translate}}
                    </button>
                    <button *ngIf="billbeeCloudService != null"
                            mat-raised-button
                            fxFlex="100"
                            type="button"
                            (click)="confirmCancelBillbeeCloud($event)"
                            [disabled]="isBookingBusy">
                        {{'Billbee Cloudspeicher löschen'|translate}}
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</bb-named-content>
