<bb-card>
    <ng-container title>{{ "title.email_address_and_external_logins" | translate }}</ng-container>
    <p>{{ "text.email_address_and_external_logins" | translate }}</p>
    <bb-input type="email" [formControl]="emailControl" [label]="'label.email' | translate" required></bb-input>
    <ng-container actions>
        <button mat-button color="accent" type="button" routerLink="/account/security">
            {{ "button.reset_password" | translate }}
        </button>
        <button mat-flat-button color="primary" type="button" (click)="saveUserEmail(emailControl.value)">
            {{ "button.save" | translate }}
        </button>
    </ng-container>
    <ng-container footer>
        <div class="padding-v" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
            <mat-divider [inset]="true" fxFlex></mat-divider>
            <div fxFlex="none" class="text-muted">{{ "text.connect_social_login" | translate }}</div>
            <mat-divider [inset]="true" fxFlex></mat-divider>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
            <bb-external-login-button
                *ngFor="let externalLogin of settings.externalLogins | mapToAllExternalLogins"
                [externalLogin]="externalLogin"
                (onClick)="addOrRemoveExternalLogin(externalLogin)"
            ></bb-external-login-button>
        </div>
    </ng-container>
</bb-card>
