<bb-column>
    <bb-row *ngIf="state$ | async as state">
		<div fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100">
			<bb-email-settings-card
				*ngIf="!state.emailSettingsLoading; else loadingCard"
				[settings]="state.emailSettings"
				(changeEmail)="changeEmail($event)"
				(clickDeleteLogin)="deleteExternalLogin($event)"
			></bb-email-settings-card>
		</div>
		<div fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100">
			<bb-service-pin-settings-card
				*ngIf="!state.servicePinSettingsLoading; else loadingCard"
				[settings]="state.servicePinSettings"				
			></bb-service-pin-settings-card>
		</div>
		<div fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100">
			<bb-mail-notification-settings-card
				*ngIf="!state.mailNotificationSettingsLoading; else loadingCard"
				[settings]="state.mailNotificationSettings"
				(settingsChange)="saveMailNotificationSettings($event)"
			></bb-mail-notification-settings-card>
		</div>
		<div fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100">
			<bb-avv-settings-card
				*ngIf="!state.avvSettingsLoading; else loadingCard"
				[settings]="state.avvSettings"
			></bb-avv-settings-card>
		</div>
        <bb-reset-account-card fxFlex="33" [fxFlex.lt-md]="50" [fxFlex.lt-sm]="100"></bb-reset-account-card>
        <bb-delete-account-card
            (clickDelete)="deleteAccount()"
            fxFlex="33"
            [fxFlex.lt-md]="50"
            [fxFlex.lt-sm]="100"
        ></bb-delete-account-card>
    </bb-row>
</bb-column>

<ng-template #loadingCard>
    <bb-loading-card></bb-loading-card>
</ng-template>
