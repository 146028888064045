import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    template: "",
})
export class EmptyComponent {
}

@Component({
    template: "<router-outlet></router-outlet>",
})
export class EmptyWithChildrenComponent {
}

@Component({
    template: "<router-outlet></router-outlet>",
})
export class MissingRouteComponent implements OnInit {
    constructor(private readonly router: Router,
    ) {
    }

    public ngOnInit(): void {
        if (this.router.url.indexOf("/de/") === 0) {
            // Try to redirect to the angular url
            this.router.navigateByUrl(this.router.url.substr(3), {});
        } else {
            console.log("Missing route: %o", window.location.href);
        }
    }
}
