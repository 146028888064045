import {Injectable} from "@angular/core";
import {InteractionService, MessagingService, TranslationService} from "@bb-core/service";
import {selectAccount} from "@bb/states/selectors";
import {ComponentStore, tapResponse} from "@ngrx/component-store";
import {Store} from "@ngrx/store";
import {Observable, zip} from "rxjs";
import {filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {AccountRepository} from "../../data/account.repository";
import {AvvSettingsDto, EMPTY_AVV_SETTINGS_DTO} from "../../data/avv-settings.dto";
import {ExternalLoginDto} from "../../data/external-login.dto";
import {
	EMPTY_MAIL_NOTIFICATION_SETTINGS_DTO,
	MailNotificationSettingsDto,
} from "../../data/mail-notification-settings.dto";
import {EMPTY_SERVICE_PIN_SETTINGS_DTO, ServicePinSettingsDto} from "../../data/service-pin-settings.dto";

interface AccountSettingsState {
    emailSettings: {
        email: string;
        externalLogins: ExternalLoginDto[];
    };
    emailSettingsLoading: boolean;
    servicePinSettings: ServicePinSettingsDto;
    servicePinSettingsLoading: boolean;
    mailNotificationSettings: MailNotificationSettingsDto;
    mailNotificationSettingsLoading: boolean;
    avvSettings: AvvSettingsDto;
    avvSettingsLoading: boolean;
}

@Injectable()
export class AccountSettingsStore extends ComponentStore<AccountSettingsState> {
    readonly loadSettings = () => {
        this.loadEmailSettings();
        this.loadAvvSettings();
        this.loadMailNotificationSettings();
        this.loadServicePinSettings();
    };

    readonly updateMailNotificationSettings = this.effect((settings$: Observable<MailNotificationSettingsDto>) =>
        settings$.pipe(
            switchMap((settings) =>
                this.accountRepository.setEmailNotificationSettings(settings).pipe(
                    tapResponse(
                        () => {
                            this.patchState({mailNotificationSettings: settings});
                            this.messageService.showSnackBar("flash.changed_email_settings");
                        },
                        () => {},
                    ),
                ),
            ),
        ),
    );

    readonly deleteExternalLogin = this.effect((externalLogin$: Observable<ExternalLoginDto>) =>
        externalLogin$.pipe(
            switchMap((externalLogin) =>
                this.openConfirmDialog().pipe(
                    filter((confirm) => !!confirm),
                    map(() => externalLogin),
                ),
            ),
            tap(() => this.patchState({emailSettingsLoading: true})),
            switchMap((externalLogin) =>
                this.accountRepository.deleteExternalLogin(externalLogin).pipe(
                    map(() => externalLogin),
                    withLatestFrom(this.state$),
                ),
            ),
            tapResponse(
                ([externalLogin, state]) =>
                    this.patchState({
                        emailSettings: {
                            email: state.emailSettings.email,
                            externalLogins: state.emailSettings.externalLogins.filter(
                                ({Provider}) => Provider !== externalLogin.Provider,
                            ),
                        },
                        emailSettingsLoading: false,
                    }),
                () => {
                    this.patchState({emailSettingsLoading: false});
                },
            ),
        ),
    );

    constructor(
        private readonly accountRepository: AccountRepository,
        private readonly accountStore: Store,
        private readonly messageService: MessagingService,
        private readonly translator: TranslationService,
		private readonly interactionService: InteractionService,
    ) {
        super({
            emailSettings: {
                email: "",
                externalLogins: [],
            },
            emailSettingsLoading: true,
            servicePinSettings: EMPTY_SERVICE_PIN_SETTINGS_DTO,
            servicePinSettingsLoading: true,
            mailNotificationSettings: EMPTY_MAIL_NOTIFICATION_SETTINGS_DTO,
            mailNotificationSettingsLoading: true,
            avvSettings: EMPTY_AVV_SETTINGS_DTO,
            avvSettingsLoading: true,
        });
    }

    private readonly loadEmailSettings = this.effect((load$) =>
        load$.pipe(
            tap(() => this.patchState({emailSettingsLoading: true})),
            switchMap(() =>
                zip(
                    this.select(this.accountStore.select(selectAccount), ({MailAddress}) => MailAddress),
                    this.accountRepository.getExternalLogins(),
                ).pipe(
                    tapResponse(
                        ([email, externalLogins]) =>
                            this.patchState({emailSettings: {email, externalLogins}, emailSettingsLoading: false}),
                        () => this.patchState({emailSettingsLoading: false}),
                    ),
                ),
            ),
        ),
    );

    private readonly loadServicePinSettings = this.effect((load$) =>
        load$.pipe(
            tap(() => this.patchState({servicePinSettingsLoading: true})),
            switchMap(() =>
                this.accountRepository.getServicePinSettings().pipe(
                    tapResponse(
                        (settings) =>
                            this.patchState({
                                servicePinSettings: settings,
                                servicePinSettingsLoading: false,
                            }),
                        () => this.patchState({servicePinSettingsLoading: false}),
                    ),
                ),
            ),
        ),
    );

    private readonly loadMailNotificationSettings = this.effect((load$) =>
        load$.pipe(
            tap(() => this.patchState({mailNotificationSettingsLoading: true})),
            switchMap(() =>
                this.accountRepository.getMailNotificationSettings().pipe(
                    tapResponse(
                        (settings) =>
                            this.patchState({
                                mailNotificationSettings: settings,
                                mailNotificationSettingsLoading: false,
                            }),
                        () => this.patchState({mailNotificationSettingsLoading: false}),
                    ),
                ),
            ),
        ),
    );

    private readonly loadAvvSettings = this.effect((load$) =>
        load$.pipe(
            tap(() => this.patchState({avvSettingsLoading: true})),
            switchMap(() =>
                this.accountRepository.getAvvSettings().pipe(
                    tapResponse(
                        (settings) => this.patchState({avvSettings: settings, avvSettingsLoading: false}),
                        () => this.patchState({avvSettingsLoading: false}),
                    ),
                ),
            ),
        ),
    );

    private openConfirmDialog(): Observable<any> {
        const t = this.translator.translate("title.delete_external_login");
        const m = this.translator.translate("text.delete_external_login");
        return this.interactionService.confirm$(t, m, {
            yesText: "button.yes",
            noText: "button.delete",
            isDangerousToProceed: true,
        });
    }
}
