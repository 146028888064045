import {Pipe, PipeTransform} from "@angular/core";
import {ExternalLoginDto} from "../../data/external-login.dto";
import {ExternalLoginProvider} from "../../model/external-login-provider.enum";

@Pipe({
    name: "mapToAllExternalLogins",
})
export class MapToAllExternalLoginsPipe implements PipeTransform {
    public transform(existingLogins: ExternalLoginDto[]): ExternalLoginDto[] {
        return Object.values(ExternalLoginProvider).map(
            (provider) =>
                existingLogins.find((login) => login.Provider === provider) || {
                    Id: null,
                    Provider: provider,
                    ExternalUserName: null,
                },
        );
    }
}
