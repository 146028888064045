import {registerLocaleData} from "@angular/common";
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import {ApplicationRef, ComponentRef, DoBootstrap, LOCALE_ID, NgModule} from "@angular/core";
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NavigationEnd, Router, RouterModule} from "@angular/router";
import {downgradeComponent, downgradeInjectable, setAngularJSGlobal, UpgradeModule} from "@angular/upgrade/static";
import {ExternalLoginsListComponent} from "@app/app/admin/user/external-logins-list/external-logins-list.component";
import {AppComponent} from "@app/app/app.component";
import {GoogleTranslationService} from "@app/app/article/service/google-translation.service";
import {EmptyComponent, MissingRouteComponent} from "@app/app/common/component/empty.component";
import {MvcComponent} from "@app/app/common/component/mvc.component";
import {BbModule} from "@app/app/common/module/bb.module";
import {IndicatorInterceptor} from "@app/app/common/service";
import {GeneralSettingsService} from "@app/app/common/service/da/general-settings.service";
import {ManualStocksyncService} from "@app/app/common/service/manual-stocksync.service";
import {MessageTemplateService} from "@app/app/common/service/message-template.service";
import {OtpService} from "@app/app/common/service/otp.service";
import {CustomerPublicApiModule} from "@app/app/customer/customer-public-api.module";
import {OAuthModule} from "@app/app/oauth/oauth.module";
import {LegacyShippingRepository} from "@app/app/shipping/data/repository";
import {ShippingHelperService} from "@app/app/shipping/infrastructure/service";
import {ShippingPublicApiModule} from "@app/app/shipping/shipping-public-api.module";
import {ErrorHandlerService} from "@bb-core/service";
import {DataModule} from "@bb-data/data.module";
import {InfrastructureModule} from "@bb-infra/infrastructure.module";
import {LegacyAlertService} from "@bb-infra/service";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {ResourceModule} from "@ngx-resource/handler-ngx-http";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {MessageTemplateRepository} from "@shared/repository";
import {QuillModule} from "ngx-quill";
import {filter, map} from "rxjs/operators";
import {AccountRepository} from "./account/data/account.repository";
import {ArticleService} from "./article/article.service";
import {BulkEditLegacyService} from "./article/service/bulk-edit-legacy.service";
import {InconsistentVatDetailsDialogComponent} from "./common/component/dialog/inconsistent-vat-details-dialog/inconsistent-vat-details-dialog.component";
import {SelectFontComponent} from "./common/directive/selectFont.component";
import {OAuthService} from "./oauth/infrastructure";
import {OnboardingAssistantModule} from "./onboarding-assistant/onboarding-assistant.module";
import * as qConfig from "./quill-config";
import {LegislativeTextsRepository} from "./settings/legislative-texts/data/legislative-texts.repository";
import {ShopHelperService} from "./settings/shops";
import {AccountEffects} from "./states/effects";
import {accountReducer} from "./states/reducers";
import {ACCOUNT_STATE} from "./states/selectors";
import {Observable, zip} from "rxjs";

export class TranslateMultiHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    public getTranslation(lang: string): Observable<Object> {
        return zip(this.http.get(`./assets/i18n/${lang}.json`), this.http.get(`./assets/i18n/${lang}_dep.json`)).pipe(
            map(([main, dep]) => ({...dep, ...main})),
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const legacyService = (serviceName: string) => (ngJsInjector: any) => ngJsInjector.get(serviceName);
registerLocaleData(localeDe, "de");

export function createTranslateLoader(http: HttpClient) {
    return new TranslateMultiHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, InconsistentVatDetailsDialogComponent],
    exports: [BbModule, RouterModule, QuillModule],
    imports: [
        UpgradeModule,
        BrowserModule,
        BrowserAnimationsModule,
        BbModule,
        OAuthModule,
        HttpClientModule,
        InfrastructureModule,
        DataModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ResourceModule.forRoot(),
        QuillModule.forRoot(qConfig.config),
        CustomerPublicApiModule,
        ShippingPublicApiModule,
        RouterModule.forRoot(
            [
                {path: "order", component: MvcComponent, data: {title: "Bestellungen"}},
                {
                    path: "customer",
                    loadChildren: () => import("./customer/customer.module").then((m) => m.CustomerModule),
                    data: {title: "Kunden"},
                },
                {path: "webdav/add/:guid", component: MvcComponent, data: {title: "WebDAV Server hinzufügen"}},
                {path: "ftp/add/:guid", component: MvcComponent, data: {title: "FTP Server hinzufügen"}},
                {path: "amazons3/add/:guid", component: MvcComponent, data: {title: "Amazon S3 Bucket hinzufügen"}},
                {path: "dropbox/authed", component: MvcComponent, data: {title: "Dropbox hinzufügen"}},
                {path: "box/authed", component: MvcComponent, data: {title: "Box hinzufügen"}},
                {
                    path: "GCPAuthCallback/IndexAsync",
                    component: MvcComponent,
                    data: {title: "Cloud Printer hinzufügen"},
                },
                {path: "gcp/listprinters/:id", component: MvcComponent, data: {title: "Cloud Printer auswählen"}},
                {path: "shiplist", component: MvcComponent, data: {title: "Versandlisten erstellen"}},
                {
                    path: "GoogleDriveAuthCallback/IndexAsync",
                    component: MvcComponent,
                    data: {title: "Google Drive hinzufügen"},
                },
                {path: "googledrive/add/:id", component: MvcComponent, data: {title: "Google Drive hinzufügen"}},
                {path: "OneDrive/Auth", component: MvcComponent, data: {title: "OneDrive hinzufügen"}},
                {path: "application/search", component: MvcComponent, data: {title: "Suche"}},
                {
                    component: MvcComponent,
                    data: {title: "Downloads zu einer Bestellung öffnen"},
                    path: "administration/download/order",
                },
                {
                    component: EmptyComponent,
                    data: {title: "Kategorien"},
                    path: "category/list",
                },
                {
                    data: {title: "Berichte"},
                    loadChildren: () => import("./report/report.module").then((m) => m.ReportModule),
                    path: "report",
                },
                {
                    data: {title: "Einstellungen"},
                    loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
                    path: "settings",
                },
                {
                    data: {title: "Benutzerkonto"},
                    loadChildren: () => import("./account/account.module").then((m) => m.AccountModule),
                    path: "account",
                },
                {
                    data: {title: "Zahlungsabgleich"},
                    loadChildren: () => import("./payments/payments.module").then((m) => m.PaymentsModule),
                    path: "payments",
                },
                {
                    data: {title: "Versand"},
                    loadChildren: () => import("./shipping/shipping.module").then((m) => m.ShippingModule),
                    path: "shipping",
                },
                {
                    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
                    path: "admin",
                },
                {
                    loadChildren: () => import("./article/article.module").then((m) => m.ArticleModule),
                    path: "article",
                },
                {
                    loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
                    path: "",
                    pathMatch: "full",
                },
                {
                    loadChildren: () => import("./signed-out/signed-out.module").then((m) => m.SignedOutModule),
                    data: {showNavigation: false},
                    path: "",
                },
                {
                    loadChildren: () =>
                        import("./settings/automation/automation-settings.module").then(
                            (m) => m.AutomationSettingsModule,
                        ),
                    path: "automation/message-template",
                },
                // Clear view for old components
                {path: "**", component: MissingRouteComponent},
            ],
            {onSameUrlNavigation: "reload"},
		),
		OnboardingAssistantModule,
        StoreModule.forRoot({[ACCOUNT_STATE]: accountReducer}),
		EffectsModule.forRoot([AccountEffects]),
    ],
    providers: [
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
        {provide: HTTP_INTERCEPTORS, useClass: IndicatorInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: "de-DE"},
        {provide: "$alert", useClass: LegacyAlertService},
        {provide: "$translate", useFactory: legacyService("$translate"), deps: ["$injector"]},
        {provide: "packageTypeResource", useFactory: legacyService("PackageTypeResource"), deps: ["$injector"]},
        {provide: "shopResource", useFactory: legacyService("ShopResource"), deps: ["$injector"]},
        {provide: "authService", useFactory: legacyService("AuthService"), deps: ["$injector"]},
        {provide: "multiSelectService", useFactory: legacyService("MultiSelectService"), deps: ["$injector"]},
        {provide: "$cookies", useFactory: legacyService("$cookies"), deps: ["$injector"]},
        {provide: "$mdDialog", useFactory: legacyService("$mdDialog"), deps: ["$injector"]},
        {provide: "pdfHelper", useFactory: legacyService("pdfHelper"), deps: ["$injector"]},
        {provide: "bookedServiceResource", useFactory: legacyService("BookedServiceResource"), deps: ["$injector"]},
        {provide: "accountResource", useFactory: legacyService("AccountResource"), deps: ["$injector"]},
        {provide: "EventResource", useFactory: legacyService("EventResource"), deps: ["$injector"]},
        {provide: "SettingsResource", useFactory: legacyService("SettingsResource"), deps: ["$injector"]},
        {provide: "$state", useFactory: legacyService("$state"), deps: ["$injector"]},
        {provide: "SignalRService", useFactory: legacyService("SignalRService"), deps: ["$injector"]},
        {provide: "NotificationResource", useFactory: legacyService("NotificationResource"), deps: ["$injector"]},
        {provide: "CurrencyResource", useFactory: legacyService("CurrencyResource"), deps: ["$injector"]},
    ],
})
export class AppModule implements DoBootstrap {
    private bootstrapped: boolean = false;
    private navigationChanges = [];

    constructor(private readonly upgrade: UpgradeModule, private readonly router: Router, translate: TranslateService) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).ngRouter = router;
        translate.setDefaultLang("de");
        translate.use("de");

        router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e) => {
            window.dispatchEvent(
                new CustomEvent("angular-navigated", {
                    detail: e,
                }),
            );
        });

        window.addEventListener("angular-js-navigated", (event: CustomEvent) => {
            if (this.bootstrapped) {
                router.navigateByUrl(event.detail).then();
            } else {
                this.navigationChanges.push(event.detail);
            }
        });
    }

    ngDoBootstrap(app: ApplicationRef) {
        this._doBootstrap(app.bootstrap(AppComponent));
    }

    private async _doBootstrap(componentRef: ComponentRef<AppComponent>): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (window as any).loadDone;
        const jsApp = await import("../angular-js/app");

        setAngularJSGlobal(jsApp.angular);
        // downgrades
        angular
            .module("app")
            .directive(
                "selectFont",
                downgradeComponent({
                    component: SelectFontComponent,
                    inputs: ["font", "userFonts"],
                    outputs: ["onChange"],
                }),
            )
            .factory("oauth-service", downgradeInjectable(OAuthService))
            .factory("general-settings-service", downgradeInjectable(GeneralSettingsService))
            .factory("otp-service", downgradeInjectable(OtpService))
            .factory("message-template-repository", downgradeInjectable(MessageTemplateRepository))
            .factory("message-template-service", downgradeInjectable(MessageTemplateService))
            .directive(
                "bbExternalLoginsList",
                downgradeComponent({
                    component: ExternalLoginsListComponent,
                    inputs: ["user"],
                }),
            )
            .factory("shipping-repository", downgradeInjectable(LegacyShippingRepository))
            .factory("shipping-helper-service", downgradeInjectable(ShippingHelperService))
            .factory("manual-stocksync-service", downgradeInjectable(ManualStocksyncService))
            .factory("error-handler-service", downgradeInjectable(ErrorHandlerService))
            .factory("GoogleTranslationService", downgradeInjectable(GoogleTranslationService))
            .factory("AlertService", downgradeInjectable(LegacyAlertService))
            .factory("account-repository", downgradeInjectable(AccountRepository))
            .factory("shop-helper-service", downgradeInjectable(ShopHelperService))
			.factory("legislative-texts-repository", downgradeInjectable(LegislativeTextsRepository))
			.factory("article-service", downgradeInjectable(ArticleService))
			.factory("bulk-edit-legacy-service", downgradeInjectable(BulkEditLegacyService));

        this.upgrade.bootstrap(document.documentElement, ["app"], {strictDi: true});
        componentRef.instance.bootstrapped();
        this.navigationChanges.forEach((s) => this.router.navigateByUrl(s).then());
        this.bootstrapped = true;

        componentRef.changeDetectorRef.detectChanges();
    }
}
