import {ChangeDetectionStrategy, Component, EventEmitter, Output} from "@angular/core";
import {FormControl} from "@angular/forms";

@Component({
    selector: "bb-delete-account-card",
    templateUrl: "delete-account-card.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountCardComponent {
    @Output() public clickDelete: EventEmitter<void> = new EventEmitter<void>();

    public readonly slideControl: FormControl = new FormControl(false);

    public deleteAccount(): void {
        if (!this.slideControl.value) {
            return;
        }

        this.clickDelete.emit();
    }
}
