import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {resetAccount} from "@bb/states/actions";

@Component({
    selector: "bb-reset-account-card",
    templateUrl: "reset-account-card.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetAccountCardComponent {
    constructor(private readonly store: Store) {}

    public resetAccount(): void {
        this.store.dispatch(resetAccount());
    }
}
