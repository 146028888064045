import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import {NonNullableFormBuilder} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MailNotificationSettingsDto} from "../../data/mail-notification-settings.dto";

@UntilDestroy()
@Component({
    selector: "bb-mail-notification-settings-card",
    templateUrl: "mail-notification-settings-card.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailNotificationSettingsCardComponent implements OnInit, OnChanges {
    @Input() public settings: MailNotificationSettingsDto = {
        ObjectSurveyParticipation: false,
        InformUserWhenOrderIsAssigned: false,
    };
    @Output() public settingsChange = new EventEmitter<MailNotificationSettingsDto>();

    public readonly mailNotificationSettingsForm = this.fb.group(this.settings);

    constructor(private readonly fb: NonNullableFormBuilder) {}

    public ngOnInit(): void {
        this.mailNotificationSettingsForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.settingsChange.emit(this.mailNotificationSettingsForm.getRawValue()));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.mailNotificationSettingsForm.patchValue(this.settings, {emitEvent: false});
    }
}
