import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateModule } from "@ngx-translate/core";
import { distinctUntilChanged } from "rxjs/operators";
import { BbFormErrorStateMatcher } from "../bb-form-error-state-matcher";
import { BbFormModule } from "../bb-form.module";

@UntilDestroy()
@Component({
    selector: "bb-input",
    templateUrl: "./bb-input.component.html",
    styleUrls: ["../bb-form.styles.scss"],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useClass: DefaultValueAccessor,
            multi: true,
        },
        {
            provide: ErrorStateMatcher,
            useClass: BbFormErrorStateMatcher,
        },
    ],
    standalone: true,
    imports: [BbFormModule, MatInputModule, TranslateModule, MatTooltipModule],
})
export class BbInputComponent implements OnInit {
    @Input() label: string = "";
    @Input() required: boolean = false;
    @Input() type: string = "text";
    @Input() customErrors: ValidationErrors[] = [];
    @Input() tooltip: Nullable<string> = null;
    @Input() hint: string | null = null;
    @Input() subscriptSizing: "dynamic" | "fixed" = "fixed";

    public control: FormControl = new FormControl<unknown>(null);

    constructor(
        private readonly formControl: NgControl,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        if (this.formControl?.control == null) {
            throw new Error("Control not found");
        }
        this.control = this.formControl.control as FormControl;
        this.control.parent?.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cdr.detectChanges());

        this.control.valueChanges
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(value => this.control.patchValue(value));
    }
}
