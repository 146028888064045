import {Component, Input} from "@angular/core";

@Component({
    selector: "bb-card",
    template: `
        <mat-card *ngIf="!isLoading; else loadingCard">
            <mat-card-title>
                <span><ng-content select="[title]"></ng-content></span>
            </mat-card-title>
            <mat-card-content>
                <ng-content></ng-content>
            </mat-card-content>
            <mat-card-actions fxLayoutAlign="end" fxLayoutGap="8px" fxLayout="row wrap">
                <ng-content select="[actions]"></ng-content>
            </mat-card-actions>
            <ng-content select="[footer]"></ng-content>
        </mat-card>

        <ng-template #loadingCard>
            <bb-loading-card></bb-loading-card>
        </ng-template>
    `,
    styleUrls: ["./bb-card.component.scss"],
})
export class BbCardComponent {
    @Input()
    public isLoading: boolean = false;
}
