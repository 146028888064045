import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {ExternalLoginDto} from "../../data/external-login.dto";
import {ExternalLoginProvider} from "../../model/external-login-provider.enum";

@Component({
    selector: "bb-external-login-button",
    templateUrl: "external-login-button.component.html",
    styleUrls: ["./external-login-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalLoginButtonComponent {
    public readonly ExternalLoginProvider = ExternalLoginProvider;

    @Input() public readonly externalLogin: Nullable<ExternalLoginDto> = null;
    @Output() public onClick: EventEmitter<void> = new EventEmitter();
}
