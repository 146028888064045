import {Component} from "@angular/core";

@Component({
    selector: "bb-row",
    template: `
        <div class="flex-container" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start stretch">
            <ng-content></ng-content>
        </div>
    `,
})
export class BbRowComponent {

}
