import {Component} from "@angular/core";

@Component({
    selector: "bb-column",
    template: `
        <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="stretch start">
            <ng-content></ng-content>
        </div>
    `,
})
export class BbColumnComponent {

}
