import {Component, Inject, OnInit} from "@angular/core";
import * as model from "@app/app/common/model";
import {AccountService} from "@app/app/common/service/da/account.service";
import {BookedService} from "@app/app/common/service/da/booked-services.service";

@Component({
    selector: "app-account-overview",
    templateUrl: "./overview.component.html",
})
export class OverviewComponent implements OnInit {
    public statusSelected = "6";

    public profile: model.UserProfile;
    public currentInvoice: model.Invoice;
    public userRoles: any = {};

    public isBookingBusy: boolean = false;
    public billbeeCloudService: model.BookedService = null;
    public isInTrial: boolean = true;
    public fromAssistent: boolean = false;
    private static fundingStates: string[] =
        ["Entwurf", "Ziel noch nicht erreicht", "Ziel erreicht", "Wird entwickelt", "Fertig", "Abgebrochen"];

    constructor(private accountService: AccountService,
                private bookedService: BookedService,
                @Inject("$alert") public alert: AlertService,
                @Inject("$translate") private $translate: ng.translate.ITranslateService,
                @Inject("authService") public readonly authService: AuthService,
    ) {
    }

    public async ngOnInit() {
        this.profile = await this.accountService.getDetails();
        this.currentInvoice = await this.accountService.getCurrentInvoice();
        this.isInTrial = new Date(this.profile.BillFrom) > new Date();
        const bookedServices = await this.bookedService.getList();
        this.setBillbeeCloudService(bookedServices);
    }

    public featureStateText(state: any /*@todo: fix me*/): string {
        if (OverviewComponent.fundingStates.hasOwnProperty(state)) {
            return this.$translate.instant(OverviewComponent.fundingStates[state]);
        }

        return this.$translate.instant("Unbekannt");
    }

    public confirmBookBillbeeCloud($event: any /*@todo: fix me*/) {
        const serviceToBook: model.BookedService = {
            DisplayName: "Billbee Cloudspeicher",
            FeeDescription: `
            Dieses Modul aktiviert den Billbee Cloudspeicher für
            Bilder und digitale Dateien in deinem Account.
            Es fallen keine weiteren Kosten an`,
            Type: "BillbeeCloud",
            maxFeePerMonthNet: 0,
            text: "pro Paket",
            transactionFeeNet: 0,
        } as model.BookedService;

        const msg = `Möchtest du das Modul ${serviceToBook.DisplayName} aktivieren?`;
        this.alert.askYesNo(this.$translate.instant(msg), this.$translate.instant("Modul aktivieren"), () => {
            this.bookBillbeeCloud(serviceToBook);
        }, (): void => null);
    }

    public confirmCancelBillbeeCloud($event: any /*@todo: fix me*/) {
        const title = "Modul kündigen";
        const msg = `Möchtest du das Modul Billbee Cloudspeicher kündigen?"
                     Dadurch werden alle Bilder und Dateien, die in deinem Billbee Cloudspeicher liegen gelöscht.`;

        this.alert.askYesNo(this.$translate.instant(msg), this.$translate.instant(title), () => {
            this.cancelBillbeeCloud();
        }, (): void => null);
    }

    private async bookBillbeeCloud(serviceToBook: model.BookedService) {
        this.isBookingBusy = true;
        try {
            this.billbeeCloudService = await this.bookedService.save(serviceToBook);
            const title = this.$translate.instant("Modul gebucht");
            const msg = this.$translate.instant(`Das Modul "${serviceToBook.DisplayName}" wurde aktiviert`);
            this.alert.success(title, msg);
        } catch (e) {
            this.alert.failureFromHttpResult(e, this.$translate.instant("Fehler"));
        } finally {
            this.isBookingBusy = false;
        }
    }

    private async cancelBillbeeCloud() {
        this.isBookingBusy = true;
        try {
            await this.bookedService.delete({id: this.billbeeCloudService.Id});
            this.setBillbeeCloudService([]);
            const title = this.$translate.instant("Modul gekündigt");
            const msg = this.$translate.instant("Das Modul Billbee Cloudspeicher wurde gekündigt");
            this.alert.warning(title, msg);
        } catch (ex) {
            this.alert.failureFromHttpResult(ex, this.$translate.instant("Fehler"));
        } finally {
            this.isBookingBusy = false;
        }
    }

    public setBillbeeCloudService(bookedServices: model.BookedService[]): void {
        this.billbeeCloudService = bookedServices.filter((s) => s.Type === "BillbeeCloud"
            && s.EndDate == null).shift();
    }
}
