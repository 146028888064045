<bb-card>
    <ng-container title>{{ "title.delete_billbee_account" | translate }}</ng-container>
    <bb-column>
        <span [innerHtml]="'text.html_delete_billbee_account' | translate"></span>
        <bb-slide-toggle [label]="'label.confirm_delete' | translate" [formControl]="slideControl"></bb-slide-toggle>
    </bb-column>
    <ng-container actions>
        <button mat-flat-button color="warn" type="button" [disabled]="!slideControl.value" (click)="deleteAccount()">
            {{ "button.delete_account" | translate }}
        </button>
    </ng-container>
</bb-card>
