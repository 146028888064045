import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {AvvSettingsDto} from "../../data/avv-settings.dto";

@Component({
    selector: "bb-avv-settings-card",
    templateUrl: "avv-settings-card.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvvSettingsCardComponent {
    @Input() public settings: AvvSettingsDto = {
        CreatedDate: "",
        IsNewCustomer: true,
    };
}
