<bb-card>
    <ng-container title>{{ "title.avv_contract" | translate }}</ng-container>
    <ng-container *ngIf="settings.CreatedDate == null">
        <p>{{ "text.avv_contract_not_yet_signed" | translate }}</p>
        <p>{{ "text.click_button_to_complete_avv" | translate }}</p>
    </ng-container>
    <ng-container *ngIf="settings.CreatedDate != null">
        <p>{{ "text.download_avv" | translate }}</p>
        <p>{{ "text.avv_confirmed_on" | translate: {date: settings.CreatedDate | date: "dd.MM.yyyy"} }}</p>
    </ng-container>
    <ng-container actions>
        <ng-container *ngIf="settings.CreatedDate == null; else downloadAdvButton">
            <button
                *ngIf="settings.IsNewCustomer; else existingUserButton"
                mat-flat-button
                color="primary"
                [routerLink]="['/become-customer']"
            >
                {{ "button.become_customer_and_avv" | translate }}
            </button>
            <ng-template #existingUserButton>
                <button mat-flat-button color="primary" [routerLink]="['/avv']">
                    {{ "button.complete_avv" | translate }}
                </button>
            </ng-template>
        </ng-container>
        <ng-template #downloadAdvButton>
            <a mat-flat-button color="primary" href="/api/angular/account/dpa?generated=true">
                {{ "button.download_avv" | translate }}
            </a>
        </ng-template>
    </ng-container>
</bb-card>
